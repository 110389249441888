import React, { useState, useContext, useEffect, useRef } from "react";
import {  update } from 'ramda';
import { useParams } from "react-router-dom";
import {
    Paper,
    Grid,
    CircularProgress,
    Dialog,
    DialogContent,
    Select,
    MenuItem,
    Card,
    CardHeader,
    CardContent,
    useMediaQuery,
} from "@material-ui/core";

import { makeStyles, createStyles } from '@material-ui/core/styles';

import DoneIcon from "@material-ui/icons/Done";
import ErrorIcon from "@material-ui/icons/Error";

import { useTimer } from "./TimerContext";

import GlobalContext from "../../../hooks/GlobalContext";
import getCurbsideTracking from "../../../firebase/httpsCallable/getCurbsideTracking";
import createCheckoutStages from "../../../firebase/httpsCallable/createCheckoutStages";

import useVisits from "./useVisits";
import logo from '../../../tebo.jpg';

const useStyles = makeStyles((theme) =>
    createStyles({
        root: {
        },
        selected: {
            padding: 0,
            margin: 0,
            // marginTop: 15,
            width: '100%',
            height: 34,
            backgroundColor: "#fff",

        },
        rootNotFound: {
            width: '100%',
            height: '100vh',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            textAlign: 'center',
            backgroundImage: 'url(assets/bgPrimary.jpg)',
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center',
            backgroundSize: 'cover',
        },
        backToTop: {
            position: 'fixed',
            bottom: 20,
            right: 20,
            fontSize: 30,
            width: 40,
            height: 40,
            backgroundColor: '#0276fd',
            color: 'white',
            cursor: 'pointer',
            borderRadius: '100px',
            border: 'none',
            boxShadow: '0 5px 10px #ccc',

            '&:hover': {
                backgroundColor: '#0276fd',
                color: 'white',
            }
        },
        cardHeader: {
            backgroundColor: '#ededed',
            padding: '3px 16px',

            '& .MuiCardHeader-content': {

                '& span': {
                    color: '#0276fd',
                    // fontSize: 19
                }
            },
            '& .MuiCardHeader-action': {
                width: '25%',
                margin: 0
            }
        },
        cardHeaderLong: {
            backgroundColor: '#ededed',
            padding: '3px 16px',

            '& .MuiCardHeader-content': {

                '& span': {
                    color: '#0276fd',
                    fontSize: 19
                }
            },
            '& .MuiCardHeader-action': {
                width: '12%',
                margin: 0
            }
        }

    })
);

export default function (props) {
    const { mainCtx, navigation, localStore } = useContext(GlobalContext);
    const { visits, loadingVisits } = useVisits();
    const { onTick } = useTimer();
    const classes = useStyles();
    // const location = useLocation();
    // const history = useHistory();
    const xlScreen = useMediaQuery("(max-width:1920px)");
    const [loading, setLoading] = useState(false);
    const [loadingData, setLoadingData] = useState('');
    // ----------------------------------------
    // ----------------------------------------
    const [isVisible, setIsVisible] = useState(false)
    const [option, setOption] = useState([])
    const scrollRef = useRef(null);
    // ----------------------------------------
    // ----------------------------------------
    const [sending, setSending] = useState(false);
    const modalSecondsRemainingRef = useRef(0);
    const [modalSecondsRemaining, setModalSecondsRemaining] = useState(0);
    const [requestState, setRequestState] = useState("success");

    const [formStage, setFormStage] = useState([
        {
            box: '1',
            value: '',
        },
        {
            box: '2',
            value: '',
        },
        {
            box: '3',
            value: '',
        },
    ]);

    // ----------------------------------------
    // ----------------------------------------

    useEffect(() => {
        function fetch() {
            setLoading(true)
            getInfoTracking(mainCtx.officeId)
        }
        fetch();
    }, [])

    const getInfoTracking = (officeId) => {
        getCurbsideTracking({
            officeId: officeId,
        })
            .then((resul) => {
                const data = resul.data && resul.data.stages;
                const dataResul = Object.keys(data).map((e) => ({ ...data[e], color: '' }));
                setOption(dataResul)

                if (resul.data && resul.data && resul.data.checkoutStages) {
                    setFormStage(resul.data.checkoutStages)
                }
                setLoading(false)
            })
            .catch(err => {
                setLoading(false)
                setOption([])
            });
    }
    // ----------------------------------------
    // ----------------------------------------
    const resultData = option.map(e => {
        const tt = visits.filter(visit => visit.stageHistory[visit.stageHistory.length - 1].stageId == e.id);
        const resul = tt.sort((a, b) => {
            const atime = parseInt(a.timeOnStage);
            const btime = parseInt(b.timeOnStage);
            return atime - btime;
        })
        return { ...e, visitList: resul }
    });
    // ----------------------------------------
    // ----------------------------------------
    const onChangeTextRoom = (name) => (event) => {

        const resp = formStage.filter((x) => x.value === event.target.value)
        if (resp && resp.length > 0) {

            const indexArrayForm = formStage.findIndex((x) => x.value === event.target.value)
            let newDataArrayForm = { ...resp[0], value: '' };
            const newAddForm = update(indexArrayForm, newDataArrayForm, formStage)
            // ----------------------------------------------------------
            const resulForm = newAddForm.filter((x) => x.box === name);
            const indexArrayForm3 = newAddForm.findIndex((x) => x.box === name);
            let newDataArrayForm3 = { ...resulForm[0], value: event.target.value };
            const newAddForm3 = update(indexArrayForm3, newDataArrayForm3, newAddForm)
            setFormStage(newAddForm3);
            // ----------------------------------------------------------
            const r = newAddForm3.map(e => e.value)
            const resul = option.map((x) => ({
                ...x,
                color: r.includes(x.id) ? '#197fe8' : ''
            }));
            saveSelectedStages(newAddForm3)
            setOption(resul);

        } else {

            const resulForm = formStage.filter((x) => x.box === name);
            const indexArrayForm = formStage.findIndex((x) => x.box === name);
            let newDataArrayForm = { ...resulForm[0], value: event.target.value };
            const newAddForm = update(indexArrayForm, newDataArrayForm, formStage)
            setFormStage(newAddForm);
            // ----------------------------------------------------------
            const r = newAddForm.map(e => e.value)
            const resul = option.map((x) => ({
                ...x,
                color: r.includes(x.id) ? '#197fe8' : ''
            }));

            saveSelectedStages(newAddForm)
            setOption(resul);

        }

    }
    // ----------------------------------------
    // ----------------------------------------
    const saveSelectedStages = (formStage) => {
        createCheckoutStages({
            officeId: mainCtx.officeId,
            formStage
        })
            .then((resul) => {
            })
            .catch(err => {
            });
    }
    // ----------------------------------------
    // ----------------------------------------

    return (
        <Grid
            container
            // style={{ minHeight: "80vh" }}
            justify="center"
            alignItems="center"
        >
            <div style={{ width: '100%' }}>

                <div style={{ textAlign: 'center', marginTop: 20, marginBottom: 20 }}>
                    <img src={logo} alt="logo" style={{ height: 'auto', width: '20%' }} />
                    <p style={{ fontSize: 24, fontWeight: 900, color: '#197FE8', textAlign: 'center', margin: 0, width: '100%' }} >{localStore.selectedOfficeName}</p>
                </div>

                <Paper style={{ height: "100%", padding: "20px", backgroundColor: '#f9faff' }}>
                    {!sending && (

                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={6} md={6} lg={6}>
                                <Card>
                                    <CardHeader
                                        titleTypographyProps={{ variant: xlScreen ? 'h6' : 'h4' }}
                                        className={classes.cardHeader}
                                        title={`Please Proceed to ${option.filter(y => y.id === formStage[0].value).map(e => { return e.name })}`}
                                        action={
                                            <Select
                                                labelId="room-simple-select1-label"
                                                id="room-simple-select1"
                                                value={formStage[0].value}
                                                variant="outlined"
                                                className={classes.selected}
                                                onChange={onChangeTextRoom(formStage[0].box)}>
                                                <MenuItem value="">
                                                    <em>-- select --</em>
                                                </MenuItem>
                                                {
                                                    option.map(roo => {
                                                        return (
                                                            <MenuItem
                                                                key={roo.id}
                                                                value={roo.id}
                                                                style={{ color: roo.color }}
                                                            >
                                                                {roo.name}
                                                            </MenuItem>
                                                        );
                                                    })
                                                }
                                            </Select>
                                        }
                                    />
                                    <CardContent style={{ minHeight: 100 }}>
                                        {resultData.length > 0 ? resultData.filter(y => y.id === formStage[0].value).map((e, index) => {
                                            return (
                                                <div key={index} style={{ fontSize: xlScreen ? 16 : 30 }} >
                                                    {e.visitList.length > 0 ? e.visitList.map((z, ind) => {
                                                        return (
                                                            <div key={ind}>{z.name.slice(0, 2)} {z.lastName}</div>
                                                        )
                                                    }) : <div>No Patient</div>}
                                                </div>
                                            )
                                        }) : null}
                                    </CardContent>
                                </Card>
                            </Grid>
                            <Grid item xs={12} sm={6} md={6} lg={6}>
                                <Card>
                                    <CardHeader
                                        className={classes.cardHeader}
                                        titleTypographyProps={{ variant: xlScreen ? 'h6' : 'h4' }}
                                        title={`Please Proceed to ${option.filter(y => y.id === formStage[1].value).map(e => { return e.name })}`}
                                        action={
                                            <Select
                                                labelId="room-simple-select2-label"
                                                id="room-simple-select2"
                                                value={formStage[1].value}
                                                variant="outlined"
                                                className={classes.selected}
                                                onChange={onChangeTextRoom(formStage[1].box)}>
                                                <MenuItem value="">
                                                    <em>-- select --</em>
                                                </MenuItem>
                                                {
                                                    option.map(roo => {
                                                        return (
                                                            <MenuItem
                                                                key={roo.id}
                                                                value={roo.id}
                                                                style={{ color: roo.color }}
                                                            >
                                                                {roo.name}
                                                            </MenuItem>
                                                        );
                                                    })
                                                }
                                            </Select>
                                        }
                                    />
                                    <CardContent style={{ minHeight: 100 }}>
                                        {resultData.length > 0 ? resultData.filter(y => y.id === formStage[1].value).map((e, index) => {
                                            return (
                                                <div key={index} style={{ fontSize: xlScreen ? 16 : 30 }}>
                                                    {e.visitList.length > 0 ? e.visitList.map((z, ind) => {
                                                        return (
                                                            <div key={ind}>{z.name.slice(0, 2)} {z.lastName}</div>
                                                        )
                                                    }) : <div>No Patient</div>}
                                                </div>
                                            )
                                        }) : null
                                        }
                                    </CardContent>
                                </Card>
                            </Grid>
                            <Grid item xs={12} lg={12}>
                                <Card>
                                    <CardHeader
                                        className={classes.cardHeaderLong}
                                        titleTypographyProps={{ variant: xlScreen ? 'h6' : 'h4' }}
                                        title={`${option.filter(y => y.id === formStage[2].value).map(e => { return e.name })}`}
                                        action={
                                            <Select
                                                labelId="room-simple-select3-label"
                                                id="room-simple-select3"
                                                value={formStage[2].value}
                                                variant="outlined"
                                                className={classes.selected}
                                                onChange={onChangeTextRoom(formStage[2].box)}>
                                                <MenuItem value="">
                                                    <em>-- select --</em>
                                                </MenuItem>
                                                {
                                                    option.map(roo => {
                                                        return (
                                                            <MenuItem
                                                                key={roo.id}
                                                                value={roo.id}
                                                                style={{ color: roo.color }}
                                                            >
                                                                {roo.name}
                                                            </MenuItem>
                                                        );
                                                    })
                                                }
                                            </Select>
                                        }
                                    />
                                    <CardContent style={{ minHeight: 200, overflowY: 'scroll', maxHeight: 250 }}>
                                        {resultData.length > 0 ? resultData.filter(y => y.id === formStage[2].value).map((e, index) => {
                                            return (
                                                <div key={index} style={{ fontSize: xlScreen ? 16 : 30 }}>
                                                    {e.visitList.length > 0 ? e.visitList.map((z, ind) => {
                                                        return (
                                                            <div key={ind}>{z.name.slice(0, 2)} {z.lastName}</div>
                                                        )
                                                    }) : <div>No Patient</div>}
                                                </div>
                                            )
                                        }) : null
                                        }
                                    </CardContent>
                                </Card>
                            </Grid>
                        </Grid>
                    )}
                    {sending && <CircularProgress></CircularProgress>}
                </Paper>
            </div>

            <Dialog open={modalSecondsRemaining > 0}>
                <DialogContent>
                    {requestState === "success" && (
                        <DoneIcon
                            style={{ fontSize: "50px", color: "green" }}
                        ></DoneIcon>
                    )}
                    {requestState === "error" && (
                        <ErrorIcon
                            style={{ fontSize: "50px", color: "green" }}
                        ></ErrorIcon>
                    )}
                </DialogContent>
            </Dialog>
            <Dialog open={loading}>
                <DialogContent>
                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                        <CircularProgress size={50} />
                        Loading ...
                    </div>
                </DialogContent>
            </Dialog>
        </Grid >
    );
}
