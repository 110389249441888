import React, { useContext } from "react";
import mainRouterHistory from "./routers/mainRouterHistory";
import { Router, Route } from "react-router-dom";
import Offices from "./components/pages/Offices/Offices";
import SignIn from "./components/pages/SignIn/SignIn";
import CheckOut from "./components/pages/CheckOut/CheckOut";
import Licenses from "./components/pages/Licenses/Licenses";
import GlobalContext, { useGlobalContext } from "./hooks/GlobalContext";
import MainAppBar from "./components/MainAppBar/MainAppBar";
import { CssBaseline } from "@material-ui/core";
import { theme } from "./theme/theme";
import { ThemeProvider } from "@material-ui/styles";

function App() {
    const globalCtx = useGlobalContext();

    return (
        <ThemeProvider theme={theme}>
            <GlobalContext.Provider value={globalCtx}>
                <CssBaseline></CssBaseline>
                <MainAppBar></MainAppBar>
                <Router history={mainRouterHistory}>
                    <Route exact path="/signin" component={SignIn}></Route>
                    <Route exact path="/offices" component={Offices}></Route>
                    <Route exact path="/licenses" component={Licenses}></Route>
                    <Route
                        exact
                        path="/checkout"
                        component={CheckOut}
                    ></Route>
                </Router>
            </GlobalContext.Provider>
        </ThemeProvider>
    );
}

export default App;
