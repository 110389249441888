import React, { useContext, useEffect } from "react";
import {
    Card,
    CardContent,
    CardHeader,
    ListItem,
    List,
    ListItemText,
    Grid,
    Button
} from "@material-ui/core";
import selectLicense from "../../../firebase/httpsCallable/selectLicense";

import GlobalContext from "../../../hooks/GlobalContext";
import { isEmpty, isNil } from "ramda";

export default function(props) {
    const { licensesDoc, authCtx, mainCtx, navigation, localStore } = useContext(
        GlobalContext
    );
    const { officeId } = mainCtx;
    const { initializing, setInitializing } = licensesDoc;

    const matchingLicenseId = Object.keys(licensesDoc.licenses).find(
        licenseId => {
            return (
                licensesDoc.licenses[licenseId].inUse === authCtx.authTime
            );
        }
    );

    useEffect(() => {
        if ( !isNil(matchingLicenseId) && !isEmpty(matchingLicenseId) && initializing) {
            selectLicense({ officeId, licenseId: matchingLicenseId }).then((resul) => {
                navigation.toMainApp(officeId);
            });
        }
    }, [matchingLicenseId, initializing])

    return (
        <Grid
            container
            alignItems="center"
            alignContent="center"
            align="center"
            spacing={2}
            style={{ marginTop: "10px", justifyContent: 'center' }}
        >
            {authCtx.isSuperAdmin && (
                <Grid item xs={12}>
                    <Button
                        variant="contained"
                        onClick={() => {
                            navigation.toMainApp(officeId);
                        }}
                    >
                        SUPERADMIN
                    </Button>
                </Grid>
            )}
            <Grid item xs={12} md={6} lg={6}>
                <Card>
                    <CardHeader title="Licenses" />
                    <CardContent>
                        <List>
                            {Object.keys(licensesDoc.licenses).map(
                                licenseId => {
                                    return (
                                        <License
                                            key={licenseId}
                                            license={{
                                                ...licensesDoc.licenses[
                                                    licenseId
                                                ],
                                                licenseId
                                            }}
                                        />
                                    );
                                }
                            )}
                        </List>
                    </CardContent>
                </Card>
            </Grid>
        </Grid>
    );
}

function License(props) {
    const { navigation, mainCtx, authCtx, localStore } = useContext(GlobalContext);
    const { officeId } = mainCtx;
    const license = props.license;
    const isSelected = authCtx.authTime === license.auth_time;

    const verify = (license) => () => {

            selectLicense({ officeId, licenseId: license.licenseId }).then(
                () => {
                   
                    navigation.toMainApp(officeId);
                }
            );
      

    }

    return (
        <ListItem
            selected={isSelected}
            button
            divider
            onClick={verify(license)}
        >
            <ListItemText primary={license.friendlyName} />
        </ListItem>
    );
}
