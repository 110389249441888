import React, { useState, useEffect, useContext, useRef } from "react";
import getOfficesMeta from "../../../firebase/httpsCallable/getOfficesMeta";
import {
  ListItem,
  ListItemText,
  List,
  CircularProgress
} from "@material-ui/core";
import GlobalContext from "../../../hooks/GlobalContext";

export default function(props) {
  const [loading, setLoading] = useState(false);
  const [offices, setOffices] = useState([]);

  useEffect(() => {
    (async () => {
      setLoading(true);
      const response = await getOfficesMeta();
      const officeObj = response.data;
      setOffices(
        Object.keys(officeObj).map(officeId => {
          return { ...officeObj[officeId], officeId };
        })
      );
      setLoading(false);
    })();
  }, []);

  if (loading) {
    return <CircularProgress size={30} />;
  }

  return (
    <React.Fragment>
      <List>
        {offices
          .sort((a, b) => {
            const nameA = a.name;
            const nameB = b.name;
            if (nameA > nameB) {
              return 1;
            }
            if (nameA < nameB) {
              return -1;
            }
            return 0;
          })
          .map(office => {
            return (
              <OfficeListItem office={office} key={office.name}>
                {office.name}
              </OfficeListItem>
            );
          })}
      </List>
    </React.Fragment>
  );
}

function OfficeListItem(props) {
  const { navigation, mainCtx, localStore, licensesDoc } = useContext(GlobalContext);
  const office = props.office;
  return (
    <ListItem
      button
      divider
      onClick={() => {
        localStore.updateSelectedOfficeId(office.officeId);
        mainCtx.setOfficeId(office.officeId);
        localStore.updateSelectedOfficeName(office.name);
        navigation.toMainApp(office.officeId);
        // navigation.toPatientSignIn(office.officeId);
      }}
    >
      <ListItemText primary={office.name} />
    </ListItem>
  );
}
